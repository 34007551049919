<template>
    <v-dialog v-model="isAskSignatureOpen" width="100%" max-width="600">
        <v-col>
            <v-card class="pa-2" align=center>
                <v-row>
                    <v-col align="left">
                        <v-card-title>
                            Envoyer l'ordre d'intervention : {{ itemsData['N° RAP COMP'] }} par mail
                        </v-card-title>
                    </v-col>
                    <v-col align="right">
                        <v-btn @click="isAskSignatureOpen = false" color="red" density="compact" icon="mdi-close"
                            variant="text" class="ma-2">
                        </v-btn>
                    </v-col>
                </v-row>
                <v-col align="center">
                    <v-text-field dense hide-details variant="outlined" :label="'Email destinataire'"
                        v-model="email_pdf">
                    </v-text-field>
                    <v-col align=center>
                        <v-btn @click="addEmailToListe">
                            Ajouter
                        </v-btn>
                    </v-col>
                    <v-col>
                        <h3 align=center class="mb-2">
                            Liste des emails de destinataire ({{ emailSignatureListe.length }})
                        </h3>
                        <v-row>
                            <v-col v-for="(email, idx) in emailSignatureListe" :key="idx" cols="auto">
                                <v-chip @click="deleteElemFromListeByIndex(emailSignatureListe, idx)">
                                    {{ email }}
                                </v-chip>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- <v-menu open-on-hover open-delay="0" close-delay="0" v-if="emailList && emailList.length != 0">
                        <template v-slot:activator="{ props }">
                            <v-btn color="primary" v-bind="props" class="mt-2" density="compact" variant="tonal">
                                Email prédéfini
                            </v-btn>
                        </template>
<v-list>
    <v-list-item v-for="(item, index) in emailList" :key="index" :value="index">
        <v-list-item-title @click="email_pdf = item">{{ item }}</v-list-item-title>
    </v-list-item>
</v-list>
</v-menu> -->
                </v-col>
                <v-btn :disabled="emailSignatureListe.length == 0" color="#5C6BC0" @click="send_pdf()" class="mt-2"
                    style="color:white">
                    Envoyer
                </v-btn>
            </v-card>
        </v-col>
    </v-dialog>
    <v-dialog v-model="isSignatureOpen" width="600px">
        <v-card tile>
            <signature-pad id="signature" ref="signaturePad" width="350px" :options="{
        onBegin: () => {
            $refs['signaturePad'].resizeCanvas();
        },
    }" />
            <v-card color="#eeeeee" tile>
                <v-col align="right">
                    <v-btn text elevation="0" color="green" variant="tonal" class="mx-2"
                        @click="(isSignatureOpen = false), save('signaturePad')">
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    <v-btn color="blue" class="mx-2" variant="tonal" @click="undo('signaturePad')" align="right">
                        <v-icon>mdi-undo</v-icon>
                    </v-btn>
                    <v-btn color="red" variant="tonal" class="mx-2" @click="clear('signaturePad')">
                        <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                </v-col>
            </v-card>
        </v-card>
    </v-dialog>
    <v-dialog v-model="isPhotoViewerOpen" max-width="2000px" width="90%">
        <v-card color="rgb(0,0,0,0.2)">
            <div v-if="form.photos && form.photos.length !== 0">
                <v-carousel height="90vh" hide-delimiters v-model="carouselIndex">
                    <v-carousel-item v-for="(item, i) in form.photos" :key="i" :src="item">
                        <v-sheet color="transparent" height="100%" align="right">
                            <v-row>
                                <v-col align="right" cols="" class="px-0">
                                    <v-btn :disabled="!isEditable" class="ma-2" color="red" variant="text" @click="function () {
        deleteElemFromListeByIndex(form.photos, i)
        carouselIndex = carouselIndex - 1
    }
        ">
                                        <v-icon :size="35" color="red"> mdi-delete </v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto" align="right" class="pl-0">
                                    <v-btn fab variant="text" small class="ma-2" style="float: right">
                                        <v-icon :size="40" color="red" @click="isPhotoViewerOpen = false">
                                            mdi-close-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </div>
            <div v-else>
                <v-card>
                    <v-col>
                        Aucune photo enregistrée </v-col>
                </v-card>
            </div>
        </v-card>
    </v-dialog>
    <v-col v-if="isEditable == false" cols="auto" align=center
        style="position: fixed;bottom: 0px;right: 10px; z-index: 10;">
        <v-card color="red" style="color:black;font-weight: bold;" class="pa-1 px-3">
            Cet OR est signé et cloturé il n'est donc plus modifiable.
        </v-card>
    </v-col>
    <div class="sav-form" id="info">
        <v-col v-if="isPrinting == false">
            <v-row>
                <div id="hideInPrint" style="position:absolute;right:10px;top:10px">
                    <v-btn icon="mdi-close-circle" variant="text" color="#E57373" style="font-size: 25px;"
                        @click="closeModal()">
                    </v-btn>
                </div>

                <v-col style="color: #90A4AE;" align="center" cols="12">
                    <h1 style="text-transform: uppercase;">
                        Ordre d'intervention : {{ itemsData['N° RAP COMP'] }}
                    </h1>
                </v-col>
                <v-col id="hideInPrint">
                    <v-row class="pa-2">
                        <v-card class="pa-4 ml-3 mb-2"
                            style="border:solid 8px #7986CB;text-transform: uppercase; font-weight: bold;">
                            Champ modifiable
                        </v-card>
                        <v-card class="pa-4 ml-3 mb-2"
                            style="border:solid 8px #E57373;text-transform: uppercase; font-weight: bold;">
                            Champ obligatoire
                        </v-card>
                    </v-row>
                </v-col>
                <v-divider></v-divider>
                <v-col cols="12">
                    <v-row justify=center align=center>
                        <v-col cols="12" sm="12" md="6">
                            <div class="stepContainer">
                                <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                                    Information client
                                </h3>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card v-if="optionsObj.use_search_filter != false" class="mb-2 pa-1"
                                            align=center id="hideInPrint">
                                            <v-text-field hide-details label="Rechercher un client"
                                                v-model="listeShortData['clientValue']"></v-text-field>
                                            <div v-if="listeShortData['client']" align="left" class="pa-2 pb-0"
                                                style="font-size:12px">
                                                Liste de client trouvé {{ listeShortData['client'].length }}
                                            </div>
                                            <v-btn class="my-2" variant="tonal"
                                                @click="getClientListeByName(listeShortData['clientValue'])">Rechercher</v-btn>
                                        </v-card>
                                        <v-card class="pa-2" variant="flat" color="#E57373" id="cardEdition">
                                            <v-autocomplete v-model="form['client']" class="edition pa-2"
                                                :items="listeShortData['client']" :readonly="!isEditable"
                                                item-title="SOCIETE" return-object label="Client" variant="underlined"
                                                hide-details bg-color="white"
                                                no-data-text="Modifier la recherche de client pour plus de proposition">
                                            </v-autocomplete>
                                            <!-- :readonly="(itemsData && itemsData.Table == 'SAV_FICHE') || !isEditable" -->
                                            <!-- <v-card id="hideInPrint" v-if="itemsData && itemsData.Table == 'SAV_FICHE'" color="red"
                                        style="color:black;font-weight: bold;border-radius: 0px;" class="px-2 py-1">
                                        Cette Information n'est pas modifiable
                                    </v-card> -->
                                        </v-card>
                                        <v-checkbox class="px-0 mx-0" density="compact" id="hideInPrint" hide-details
                                            v-model="showDetails['client']"
                                            label="Afficher plus de details"></v-checkbox>
                                    </v-col>
                                    <v-col v-if="showDetails['client']">
                                        <v-row>
                                            <v-col cols="6" v-for="elem in clientDataToShowElem" :key="elem.key">
                                                <v-card class="px-4 py-1" variant="text"
                                                    style="border: solid 1px lightgray;background-color: white;height: 100%;">
                                                    <div style="font-size: 12px; font-weight: normal;">
                                                        {{ elem.label }}
                                                    </div>
                                                    <div style="font-size: 19px; font-weight: normal;">
                                                        {{ form['client'][elem.key] }}
                                                    </div>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-card class="px-4 py-1" variant="text"
                                                    style="border: solid 1px lightgray;background-color: white;height: 100%;">
                                                    <div style="font-size: 12px; font-weight: normal;">
                                                        Tyde de travaux
                                                    </div>
                                                    <div style="font-size: 19px; font-weight: normal;">
                                                        {{ itemsData['type_travaux'] }}
                                                    </div>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" v-if="form['client']">
                                        <h3 class="pt-3 pl-0" style="text-transform:uppercase ;">
                                            Information d'intervention
                                        </h3>
                                    </v-col>
                                    <v-col cols="6" v-for="elem in clientDataLivToShowElem" :key="elem.key">
                                        <v-card class="pa-2 px-4" variant="text"
                                            style="height: 100%;border: solid 1px lightgray; background-color: white;">
                                            <div style="font-size: 12px; font-weight: normal;">
                                                {{ elem.label }}
                                            </div>
                                            <div style="font-size: 19px; font-weight: normal;">
                                                {{ form.client[elem.key] }}
                                            </div>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-card variant="flat" :color="getColorByKey('LIEU_INTERV')" class="pa-2"
                                            id="cardEdition">
                                            <v-text-field v-model="form['LIEU_INTERV']" label="Lieu d'intervention"
                                                :readonly="!isEditable" class="edition pa-2"
                                                style="background-color:white" variant="underlined"
                                                hide-details></v-text-field>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <div class="stepContainer">
                                <div id="techn">
                                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                                        Date du rapport
                                    </h3>
                                    <v-card variant="flat" id="cardEdition" :color="getColorByKey('DATE RAPPORT')"
                                        class="pa-2">
                                        <v-text-field type="date" class="edition pa-2" v-model="form['DATE RAPPORT']"
                                            hide-details variant="underlined" :readonly="!isEditable">
                                        </v-text-field>
                                    </v-card>
                                </div>
                                <div id="techn">
                                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                                        Technicien principal
                                    </h3>
                                    <v-card variant="flat" id="cardEdition" color="#E57373" class="pa-2">
                                        <v-select class="edition pa-2" v-model="form['technicien']"
                                            :items="technicienListe" return-object :item-title="'NOM_TECHNICIEN'"
                                            hide-details variant="underlined" :readonly="!isEditable">
                                        </v-select>
                                    </v-card>
                                </div>
                                <div class="">
                                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                                        Information machine
                                    </h3>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card
                                                v-if="form['materielUnknow'] != true && optionsObj.use_search_filter != false"
                                                class="mb-2 pa-1" align=center id="hideInPrint">
                                                <v-text-field hide-details label="Rechercher un matricule"
                                                    v-model="listeShortData['matriculeValue']"></v-text-field>
                                                <div v-if="listeShortData['matricule']" align="left" class="pa-2 pb-0"
                                                    style="font-size:12px">
                                                    Liste de matricule trouvé {{ listeShortData['matricule'].length }}
                                                </div>
                                                <v-btn class="my-2" variant="tonal"
                                                    @click="getMatriculListeByName(listeShortData['matriculeValue'])">Rechercher</v-btn>
                                            </v-card>
                                            <v-card
                                                v-if="form['materielUnknow'] == false || form['materielUnknow'] == undefined"
                                                variant="flat" color="#E57373" class="pa-2" id="cardEdition">
                                                <v-autocomplete class="edition pa-2" v-model="form['materiel']"
                                                    return-object item-title="MATRICULE" :readonly="!isEditable"
                                                    :items="listeShortData['matricule']" hide-details label="Matricule"
                                                    variant="underlined"
                                                    no-data-text="Modifier la recherche de matricule pour plus de proposition">
                                                </v-autocomplete>
                                            </v-card>
                                            <v-card v-else variant="flat" :color="getColorByKey('DATE RAPPORT')"
                                                class="pa-2" id="cardEdition">
                                                <v-text-field class="edition pa-2" v-model="form['materielMatricul']"
                                                    return-object :readonly="!isEditable" hide-details label="Matricule"
                                                    variant="underlined">
                                                </v-text-field>
                                            </v-card>
                                            <v-row>
                                                <v-col>
                                                    <v-checkbox v-model="form['materielUnknow']"
                                                        label="Matricule inconnue" hide-details></v-checkbox>
                                                </v-col>
                                                <v-col>
                                                    <v-checkbox class="px-0 mx-0" id="hideInPrint" hide-details
                                                        v-model="showDetails['matricul']"
                                                        label="Afficher plus de details"></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col v-if="form['materiel'] && form['materielUnknow'] != true">
                                            <v-row>
                                                <v-col v-if="showDetails['matricul']">
                                                    <v-row>
                                                        <v-col cols="6" v-for="  elem   in   materielElemToShow  "
                                                            :key="elem.key">
                                                            <v-card class="px-4 py-1" variant="flat"
                                                                style="border: solid 1px lightgray;">
                                                                <div style="font-size: 12px; font-weight: normal;">
                                                                    {{ elem.label }}
                                                                </div>
                                                                <div style="font-size: 19px; font-weight: normal;">
                                                                    {{ form['materiel'][elem.key] }}
                                                                </div>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-row>
                                                        <!-- <v-col cols="6">
                                                            <v-card variant="flat" :color="getColorByKey('Kilometrage')"
                                                                class="pa-2" id="cardEdition">
                                                                <v-text-field class="edition pa-2"
                                                                    v-model="form['Kilometrage']" hide-details
                                                                    label="Trajet en KM" variant="underlined"
                                                                    :readonly="!isEditable">
                                                                </v-text-field>
                                                            </v-card>
                                                        </v-col> -->
                                                        <v-col v-if="isRetour" cols="6">
                                                            <v-card variant="flat" height="100%" class="pa-4">
                                                                <div style="font-size: 12px;" class="py-1 ">Compteur
                                                                    horaire de sortie</div>
                                                                {{ itemsData['old_Horametre'] }}
                                                            </v-card>
                                                        </v-col>
                                                        <v-col cols="">
                                                            <v-card variant="flat" id="cardEdition"
                                                                :color="getColorByKey('Horametre')" class="pa-2">
                                                                <v-text-field class="edition pa-2"
                                                                    v-model="form['Horametre']" hide-details
                                                                    :label="isRetour ? 'Compteur horaire de retour' : 'Compteur horaire'"
                                                                    variant="underlined" :readonly="!isEditable">
                                                                </v-text-field>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12"
                    v-if="optionsObj.key_to_hide == undefined || (optionsObj.key_to_hide && !optionsObj.key_to_hide.split(',').includes('type_travaux'))">
                    <div class=" stepContainer">
                        <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                            Type de travaux
                        </h3>
                        <v-card variant="flat" :color="getColorByKey('type_travaux')" class="pa-2" id="cardEdition">
                            <v-select v-model="form['type_travaux']" v-if="optionsObj && optionsObj.inter_type"
                                :items="optionsObj.inter_type.split(',')" label="Type de travaux"
                                :readonly="!isEditable" class="edition pa-2" variant="underlined"
                                hide-details></v-select>
                            <v-text-field v-else v-model="form['type_travaux']" label="Type de travaux"
                                :readonly="!isEditable" class="edition pa-2" variant="underlined"
                                hide-details></v-text-field>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="stepContainer">
                        <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                            Travaux demandés
                        </h3>
                        <v-card variant="flat" id="cardEdition" :color="getColorByKey('themeDevis')" class="pa-2">
                            <v-textarea v-model="form['themeDevis']" label="Travaux demandés..." :readonly="!isEditable"
                                class="edition pa-2" variant="underlined" hide-details></v-textarea>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div id="hideInPrint" class="stepContainer" align=center>
                        <h3 class="pa-3 pl-0" style="text-transform:uppercase ;" align="left">
                            Message interne
                        </h3>
                        <v-col cols="12" class="px-0"
                            v-if="form['Message_Interne'] && form['Message_Interne'].split(',').filter((val) => val != '').length > 3">
                            <v-card variant="flat" class="pa-3" style="border:solid 1px grey">
                                <v-row>
                                    <v-col
                                        v-for="(elem, idx) in form['Message_Interne'].split(',').filter((val) => val != '')"
                                        :key="elem" cols="12" class="pa-0">
                                        <v-card class="pa-2" variant="flat" :color="idx % 2 == 0 ? '#CFD8DC' : 'white'"
                                            style="font-weight: bold;border-radius:0px">
                                            <v-row>
                                                <v-col cols="6" align="left">
                                                    {{ elem.split(':')[0] }}
                                                </v-col>
                                                <v-col cols="6" align="left">
                                                    {{ elem.split(':')[1] }}
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-card v-else variant="flat" id="cardEdition" :color="getColorByKey('travauxExe')"
                            class="pa-2">
                            <v-textarea v-model="form['Message_Interne']" label="Message interne..."
                                :readonly="!isEditable" class="edition pa-2" variant="underlined"
                                hide-details></v-textarea>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="stepContainer" v-if="form['equipement'] && form['equipement'].equipement.length != 0">
                        <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                            Equipement(s)
                        </h3>
                        <v-col>
                            <v-row>
                                <v-col v-for="(equi, idx) in form['equipement'].equipement" :key="idx" cols="12" md="6">
                                    <v-card id="cardEdition" variant="flat" :color="getColorByKey('equipement')"
                                        class="pa-2" width="100%">
                                        <v-text-field v-model="equi.matricule" hide-details bg-color="white"
                                            :label="equi.description">
                                        </v-text-field>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </div>
                </v-col>
                <v-col cols="12" class="">
                    <div class="stepContainer">
                        <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                            Article(s)
                        </h3>
                        <v-row align=center v-if="isEditable">
                            <v-col cols="">
                                <v-card class="mb-2 pa-1" align=center id="hideInPrint">
                                    <v-text-field hide-details label="Rechercher un article"
                                        v-model="listeShortData['articleValue']"></v-text-field>
                                    <div v-if="listeShortData['article']" align="left" class="pa-2 pb-0"
                                        style="font-size:12px">
                                        Liste d'article trouvé {{ listeShortData['article'].length }}
                                    </div>
                                    <v-btn class="my-2" variant="tonal"
                                        @click="getArticleListeByName(listeShortData['articleValue'])">Rechercher</v-btn>
                                </v-card>
                                <v-card variant="flat" color="#7986CB" id="cardEdition" class="">
                                    <v-col cols="pa-0">
                                        <v-row justify="center" align="center">
                                            <v-col cols="" class="pa-2">
                                                <v-autocomplete
                                                    v-if="form['isSelectedAccessoirePerso'] == undefined || form['isSelectedAccessoirePerso'] == false"
                                                    class="edition pa-2" v-model="selectedAccessoire"
                                                    :items="listeShortData['article']" hide-details
                                                    no-data-text="Modifier la recherche d'article pour plus de proposition"
                                                    label="Sélectionner un article" variant="underlined"
                                                    :readonly="!isEditable" return-object></v-autocomplete>
                                                <v-text-field v-else class="edition pa-2" v-model="selectedAccessoire"
                                                    hide-details label="Nom de l'article..." variant="underlined"
                                                    :readonly="!isEditable"></v-text-field>
                                            </v-col>
                                            <v-col cols="auto" class="pa-2">
                                                <v-btn style="width:100%" class="" @click="addAcc"
                                                    :disabled="!isEditable">Ajouter</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-col cols="12" class="py-1 pl-0">
                            <v-checkbox label="Article inconnue" v-model="form['isSelectedAccessoirePerso']"
                                hide-details></v-checkbox>
                        </v-col>
                        <v-col>
                            <Vue3EasyDataTable hide-footer :headers="headers" :items="items"
                                header-text-direction="center" body-text-direction="center" @click-row="clickRow"
                                empty-message="Aucun accesoire ajouté">

                                <template #item-qte="item">
                                    <v-col class="pa-0">
                                        <v-text-field v-model="items.find((val) => val.id == item.id).qte" type="number"
                                            hide-details density="compact" :readonly="!isEditable"></v-text-field>
                                    </v-col>
                                </template>

                                <template #item-delete="item">
                                    <v-col class="pa-0" v-if="isEditable">
                                        <v-btn id="hideInPrint"
                                            @click="deleteItemFromArrayByIndex(items, items.findIndex((val) => val.id == item.id))"
                                            icon="mdi-delete" style="color: white; background-color: red;" size="30"
                                            variant="text" :disabled="!isEditable">
                                        </v-btn>
                                    </v-col>
                                </template>
                            </Vue3EasyDataTable>
                        </v-col>
                    </div>
                </v-col>
                <v-col v-if="!formKeysToHide.includes('travauxExe')" cols="12">
                    <div>
                        <div class="stepContainer">
                            <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                                Travaux réalisés
                            </h3>
                            <v-card variant="flat" id="cardEdition" :color="getColorByKey('travauxExe')" class="pa-2">
                                <v-textarea v-model="form['travauxExe']" label="Travaux réalisés..."
                                    :readonly="!isEditable" class="edition pa-2" variant="underlined"
                                    hide-details></v-textarea>
                            </v-card>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="stepContainer">
                        <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                            Commentaire
                        </h3>
                        <v-card v-if="isRetour" variant="flat" height="100%" class="mb-4 pa-2">
                            <div style="font-size: 12px;" class="py-1">Commentaire de sortie...</div>
                            {{ itemsData['old_Observ'] }}
                        </v-card>
                        <v-card variant="flat" id="cardEdition" :color="getColorByKey('observation')" class="pa-2">
                            <v-textarea v-model="form['observation']"
                                :label="isRetour ? 'Commentaire de retour...' : 'Commentaire...'"
                                :readonly="!isEditable" class="edition pa-2" variant="underlined"
                                hide-details></v-textarea>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="stepContainer">
                        <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                            Photo(s)
                        </h3>
                        <v-card variant="flat" color="#7986CB" id="cardEdition" class="pa-2">
                            <v-file-input bg-color="white" class="photo" v-model="form['addedPic']" variant="solo"
                                hide-input prepend-icon="" accept="image/png, image/jpeg" :label="'Ajout de photo'"
                                hide-details @change="updatePhotos()
        ">
                            </v-file-input>
                            <div align=center v-if="form['addedPic'] != undefined">
                                <v-btn class="mt-2" @click="addPic()" style="background-color:white"
                                    variant="flat">Ajouter
                                    ma photo</v-btn>
                            </div>
                        </v-card>
                        <v-card @click="isPhotoViewerOpen = true" class="pa-2 mt-4" variant="flat" align=center
                            style="font-size:25px" v-if="form['photos']">
                            Nombre de photo(s) : {{ form['photos'].length }}
                            <v-icon>mdi-image </v-icon>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12" id="date">
                    <div class="stepContainer">
                        <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                            Liste Date / Horaire d'intervention
                        </h3>
                        <v-row justify="center" align=center class="pb-3">
                            <v-col cols="12">
                                <v-card variant="flat" style="background-color: white;" class="pa-2">
                                    <table style="">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th v-if="optionsObj.time_selection != true">Début</th>
                                                <th v-if="optionsObj.time_selection != true">Fin</th>
                                                <th v-if="optionsObj.time_selection == true">Durée (HH:mm)</th>
                                                <th>Technicien</th>
                                                <th>Type</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="( elem, idx ) in  slotHoraireListe " :key="idx">
                                                <td align="center">{{ formatDate(elem['date'], 'DD/MM/YYYY') }}</td>
                                                <td v-if="optionsObj.time_selection != true" align="center">{{
        elem['timeStart'] }}</td>
                                                <td v-if="optionsObj.time_selection != true" align="center">{{
        elem['timeEnd'] }}</td>
                                                <td align="center" v-if="optionsObj.time_selection == true">{{
        elem['time']
    }}</td>
                                                <td align="center">{{ elem['technicien'] }}</td>
                                                <td align="center">{{ elem['type'] }}</td>
                                                <td align="center">
                                                    <div v-if="isEditable" id="hideInPrint" style="width: 100%;"
                                                        align=center>
                                                        <v-btn
                                                            @click="deleteItemFromArrayByIndex(slotHoraireListe, idx)"
                                                            icon="mdi-delete"
                                                            style="color: white; background-color: red;" size="30"
                                                            variant="text">
                                                        </v-btn>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-card v-if="isEditable" id="hideInPrint" variant="flat" color="transparent"
                            style="border:solid 8px #7986CB" class="pa-2">
                            <v-row>
                                <v-col cols="12" sm="6" class="pb-0">
                                    <v-text-field v-model="slotHoraire['date']" hide-details class="edition pa-2"
                                        type="date" variant="underlined" :disabled="!isEditable"
                                        label="Date d'intervention"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="pb-0">
                                    <v-select label="Technicien" class="edition pa-2"
                                        v-model="slotHoraire['technicien']" :items="technicienListe"
                                        item-title="NOM_TECHNICIEN" item-value="NOM_TECHNICIEN" hide-details
                                        variant="underlined" :disabled="!isEditable">
                                    </v-select>
                                </v-col>
                                <v-col v-if="optionsObj.time_selection != true" cols="12" sm="6" class="pb-0">
                                    <v-text-field v-model="slotHoraire['timeStart']" hide-details class="edition pa-2"
                                        type="time" :disabled="!isEditable" variant="underlined"
                                        label="Heure de départ"></v-text-field>
                                </v-col>
                                <v-col v-if="optionsObj.time_selection != true" cols="12" sm="6" class="pb-0">
                                    <v-text-field v-model="slotHoraire['timeEnd']" hide-details class="edition pa-2"
                                        type="time" variant="underlined" :disabled="!isEditable"
                                        label="Heure de fin"></v-text-field>
                                </v-col>
                                <v-col v-if="optionsObj.time_selection == true" cols="12" sm="6" class="pb-0">
                                    <v-text-field v-model="slotHoraire['time']" hide-details class="edition pa-2"
                                        type="time" variant="underlined" :disabled="!isEditable"
                                        label="Durée d'intervention (HH:mm)"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="pb-0">
                                    <v-card>
                                        <div class="v-label px-3 pt-2" style="font-size:12px;">
                                            Type d'intervention
                                        </div>
                                        <v-row>
                                            <v-col v-for=" elem  in  slotHoraireType " :key="elem.type">
                                                <v-checkbox hide-details v-model="slotHoraire['type']"
                                                    :label="elem.label" :value="elem.type"></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col align="center" cols="12">
                                    <v-btn @click="addSlotHoraire">
                                        Ajouter une tranche horaire
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </div>
                </v-col>
                <v-col v-if="form && form.Check_liste">
                    <div class="stepContainer">
                        <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                            Liste des vérifications
                        </h3>
                        <Vue3EasyDataTable hide-footer :headers="headers2" :items="form['Check_liste']"
                            header-text-direction="center" body-text-direction="center" @click-row="clickRow"
                            empty-message="Aucun accesoire ajouté">

                            <template #item-CH_DESIGNATION="item">
                                <div style="font-weight: bold; font-size: 15px;" align="left">
                                    {{ item.CH_DESIGNATION }}
                                </div>
                            </template>

                            <template #item-CH_ETAT="item">
                                <div class="pa-2" style="background-color: #7986CB;" id="cardEdition">
                                    <v-card variant="flat" color="white">
                                        <v-text-field
                                            v-model="form['Check_liste'].find((val) => val.CH_DESIGNATION == item.CH_DESIGNATION).CH_ETAT"
                                            hide-details density="compact" :readonly="!isEditable">
                                        </v-text-field>
                                    </v-card>
                                </div>
                            </template>

                            <template #item-CH_OLD_ETAT="item">
                                <div variant="flat" color="white" height="100%">
                                    {{ form['Check_liste'].find((val) => val.CH_DESIGNATION ==
        item.CH_DESIGNATION).CH_OLD_ETAT }}
                                </div>
                            </template>
                        </Vue3EasyDataTable>
                    </div>
                </v-col>
                <v-col cols="12" v-if="optionsObj && optionsObj.selection_specifique_client">
                    <v-card variant="flat" class="outlined stepContainer">
                        <v-col>
                            <v-row>
                                <v-col class="px-0"
                                    v-for="( elem, idx ) in  JSON.parse(optionsObj.selection_specifique_client) "
                                    :key="idx">
                                    <h3 class="" style="text-transform:uppercase ;">
                                        {{ elem.key }}
                                    </h3>
                                    <v-col>
                                        <v-row>
                                            <v-col v-for="( choice, adx ) in  elem.value " :key="adx" cols="12" md="6">
                                                <v-card variant="flat">
                                                    <v-checkbox v-model="selectionSpecifiqueClient[elem.key]"
                                                        :label="choice" hide-details style="color:black; width:100%"
                                                        :value="choice"></v-checkbox>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card variant="flat" class="outlined stepContainer">
                        <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                            Signature
                        </h3>
                        <v-col align="right" @click="writeSignature()
        ">
                            <v-card variant="flat" color="#7986CB" class="pa-2" id="cardEdition">
                                <v-img contain :src="form['signaturePad']" width="100%" height="200px"
                                    style="background-color:white"></v-img>
                            </v-card>
                        </v-col>
                    </v-card>

                </v-col>
            </v-row>
            <v-row justify="center" id="hideInPrint">
                <v-col align=center cols="12" sm="10" md="6">
                    <v-row justify=center align=center>
                        <v-col v-if="itemsData" cols="6" class="pa-1">
                            <v-btn color="#7986CB" style="color:white;width:100%" @click="save_html_into_pdf(false)">
                                Ouvrir en PDF
                            </v-btn>
                        </v-col>
                        <v-col v-if="itemsData" cols="6" class="pa-1">
                            <v-btn color="#90A4AE" style="color:white;width:100%" @click="save_html_into_pdf(true)">
                                Envoyer par Mail
                            </v-btn>
                        </v-col>
                        <v-col cols="12" class="pa-1 pb-4">
                            <v-btn v-if="isEditable" :disabled="isUsed" @click="validForm" color="#81C784"
                                style="color:white;width:100%">
                                Enregistrer mon OR
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </div>
    <div v-if="isPrinting == true"
        style="position:static; top: 0px; height: 100vh; background-color: #7986CB; z-index: 15 !important;overflow: hidden;">
        <v-row style="height:100%; overflow: hidden;" justify="center" align=center>
            <v-col align=center>
                <v-progress-circular size="170" style="padding: 20px" indeterminate color="white">
                    Génération du PDF
                </v-progress-circular>
            </v-col>
        </v-row>
    </div>
    <v-snackbar v-model="error.show" :color="error.color">
        <div style="font-weight: bold;" align="center">
            {{ error.message }}
        </div>
    </v-snackbar>
</template>

<script>


import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import { uuid } from 'vue-uuid'
import engine from '../core/DataApi'
import moment from 'moment'
import { ref } from "@vue/reactivity";
import SignaturePad from "vue3-signature-pad";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Browser } from "@capacitor/browser";
import { v4 as uuidv4 } from "uuid";

export default {
    props: {
        getOrListeAll: { type: Function },
        technicienListe: {},
        agenceListe: {},
        clientListe: {},
        parcListe: {},
        stockListe: {},
        itemsData: {},
        optionsObj: {},
        user: {},
        checkListe: {}
    },
    setup() {
        const form = ref({})
        const selectionSpecifiqueClient = ref({})
        const slotHoraire = ref({})
        const slotHoraireType = ref([
            { type: 'Main d\'oeuvre', label: 'Main d\'oeuvre' },
            { type: 'Déplacement', label: 'Déplacement' },
        ])
        const slotHoraireListe = ref([])
        const clientDataToShowElem = ref([
            { label: 'Adresse', key: 'ADRESSE' },
            { label: 'Complément d\'adresse', key: 'COMP_ADRESSE' },
            { label: 'Ville', key: 'VILLE' },
            { label: 'Code postal', key: 'CODE_POSTAL' },
            { label: 'Téléphone', key: 'TELEPHONE' },
        ])
        const clientDataLivToShowElem = ref([
            { label: 'Adresse d\'intervention', key: 'ADRESSE_LIV' },
            { label: 'Complément d\'adresse d\'intervention', key: 'COMP_ADRESSE_LIV' },
            { label: 'Ville', key: 'VILLE_LIV' },
            { label: 'Code postal', key: 'CODE_POSTAL_LIV' },
        ])

        return { form, clientDataToShowElem, clientDataLivToShowElem, slotHoraire, slotHoraireListe, slotHoraireType, selectionSpecifiqueClient }
    },
    data() {
        return {
            isRetour: false,
            showDetails: {},
            formKeysToHide: [],
            listeShortData: {},
            // findedClient: [],
            // filtreNomtech: "",
            isUsed: false,
            carouselIndex: 0,
            isPhotoViewerOpen: false,
            error: { show: false, message: undefined, color: undefined },
            pdfLink: undefined,
            emailSignatureListe: [],
            // emailList:[],
            email_pdf: undefined,
            isSignatureOpen: false,
            isEditable: true,
            isAskSignatureOpen: false,
            isPrinting: false,
            uuid: uuid.v1(),
            selectedAccessoire: undefined,
            headers: [
                { text: "Référence", value: "PIECE_REFERENCE", sortable: true },
                { text: "Désignation", value: "PIECE_DESIGNATION", sortable: true },
                { text: "Quantitée", value: "qte", sortable: true, width: '200' },
                { text: "", value: "delete", sortable: false, width: '0' },
            ],
            items: []
        }
    },
    mounted() {
        if (this.optionsObj.use_search_filter == false) {
            this.listeShortData['client'] = this.clientListe
            this.listeShortData['matricule'] = this.parcListe
            // this.listeShortData['article'] = this.stockListe
        }
        if (this.optionsObj.form_key_to_hide) {
            this.formKeysToHide = this.optionsObj.form_key_to_hide.split(',')
        }
        console.log("itemsData", this.itemsData);
        if (this.itemsData) {
            this.preloadData()
            if (this.itemsData['Statut'] == 140)
                this.isEditable = false
            if (this.form["signaturePad"] && this.form["signaturePad"].length != 0) {
                this.isEditable = false
            }
            if (this.itemsData['retour'] == 'oui') {
                this.isRetour = true
            }
        } else {
            if (this.optionsObj.inter_type)
                this.form['type_travaux'] = this.optionsObj.inter_type.split(',')[0]
            this.form['technicien'] = this.user.user_name
            this.form["date"] = moment().format('YYYY-MM-DD')
            this.form["timeStart"] = moment().set('m', 0).format('HH:mm')
            this.form["timeEnd"] = moment().add(1, 'h').set('m', 0).format('HH:mm')
            this.form["Check_liste"] = JSON.parse(this.optionsObj["Check_liste"])
            this.form['photos'] = []
            this.form['DATE RAPPORT'] = moment().format('YYYY-MM-DD')
            this.slotHoraire['technicien'] = this.user.user_name
            this.slotHoraire["date"] = this.form['DATE RAPPORT']

            if (this.checkListe && this.checkListe.length > 0) {
                this.form["Check_liste"] = this.checkListe.map((val) => ({ CH_DESIGNATION: val.libelle, CH_ETAT: '' }))
            }
        }
    },
    components: {
        Vue3EasyDataTable,
        SignaturePad
    },
    methods: {
        getColorByKey(key) {
            if (this.isPrinting)
                return 'white'
            else
                return this.getMandatoryItems.find((val) => val.key == key) ? '#E57373' : '#7986CB'
        },
        loadPicturesfromDropBox() {
            let that = this
            engine.photo_folder_liste(this.itemsData['N° RAP COMP'], function (data) {
                if (data.code == 0 && (data.data && data.data.length > 0)) {
                    let listeFileName = data.data
                    for (let i = 0; i < listeFileName.length; i++) {
                        engine.photo_download(that.itemsData['N° RAP COMP'], listeFileName[i], function (data2) {
                            if (data2.code == 0) {
                                let blob = new Blob(
                                    [new Uint8Array(data2.data.result.fileBinary.data)],
                                    { type: "image/png" }
                                );
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    let url = reader.result;
                                    that.form['photos'].push(url)
                                };
                                reader.readAsDataURL(blob);
                            }
                        })
                    }
                }
            })
        },
        updateDropboxPhotos(num) {
            let that = this
            let orNum
            if (num)
                orNum = num
            else
                orNum = this.itemsData['N° RAP COMP']
            engine.photo_folder_liste(orNum, function (data) {
                if (data.code == 0 && (data.data && data.data.length > 0)) {
                    let listeFileName = data.data
                    for (let i = 0; i < listeFileName.length; i++) {
                        engine.photo_delete(orNum, listeFileName[i], function () {
                            if (i + 1 == listeFileName.length)
                                that.updateDropboxPhotos()
                        })
                    }
                } else {
                    for (let i = 0; i < that.form['photos'].length; i++) {
                        engine.file_upload(that.form['photos'][i], orNum, ((i + 1) + " - " + moment().format('DD-MM-YYYY hh.mm.ss')), function () {
                        })
                    }
                }
            })

        },
        saveImg(base64Img, numOr) {
            engine.file_upload(base64Img, numOr, moment().format('DD-MM-YYYY hh.mm.ss'), function () {
            })
        },
        addPic() {
            let that = this

            if (this.form['photos'] == undefined)
                this.form['photos'] = []

            let file = this.form['addedPic'][0];
            var reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = function (e) {
                that.resizeBase64Img(e.target.result).then((result) => {
                    that.form['photos'].push(result)
                    that.form['addedPic'] = undefined
                });
            };
        },
        resizeBase64Img(base64) {
            return new Promise((resolve) => {
                var canvas = document.createElement("canvas");
                let img = document.createElement("img");
                img.src = base64;

                img.onload = function () {
                    let imgWidth = 720;
                    let imgHeight = 720;
                    canvas.width = imgWidth;
                    canvas.height = imgHeight;
                    var context = canvas.getContext("2d");
                    context.drawImage(img, 0, 0, imgWidth, imgHeight);
                    resolve(canvas.toDataURL());
                };
            });
        },
        updatePhotos() {
            console.log("updatePhotos", this.form['addedPic']);
        },
        closeModal() {
            this.$emit('close')
        },
        send_pdf() {
            let that = this

            engine.send_pdf_by_email(
                this.emailSignatureListe,
                this.pdfLink,
                this.itemsData['N° RAP COMP'],
                function (data) {
                    if (data.data.errorListe.length == 0) {
                        that.error.message = 'Email(s) envoyé(s)'
                        that.error.color = '#66BB6A'
                        that.error.show = true
                    }
                    else {
                        that.error.message = 'Erreur rencontré sur le(s) email(s) : ' + data.data.errorListe.join(' - ')
                        that.error.color = 'red'
                        that.error.show = true
                    }
                }
            );
            this.isAskSignatureOpen = false
        },
        deleteElemFromListeByIndex(liste, idx) {
            liste.splice(idx, 1)
        },
        addEmailToListe() {
            this.emailSignatureListe.push(this.email_pdf)
            this.email_pdf = undefined
        },
        save_html_into_pdf(sendSignature) {
            window.scrollTo(0, 0);
            let that = this;
            that.isPrinting = true;

            let data = document.querySelector("#info");
            // for (let i = 0; i < info.length; i++) info[i].style.display = "block";

            var elms = document.querySelectorAll("#hideInPrint");

            let cards = document.querySelectorAll('#cardEdition')

            console.log("cards", cards);
            for (let i = 0; i < cards.length; i++) cards[i].style.backgroundColor = "white";


            // let data = document.querySelector("#captureMateriel");

            // let form = document.querySelector("#formContainer");
            // form.style.height = "auto";

            data.style.width = "1000px";

            for (let i = 0; i < elms.length; i++) elms[i].style.display = "none";
            let html2canvasOptions = {
                allowTaint: true,
                removeContainer: true,
                backgroundColor: null,
                imageTimeout: 15000,
                logging: true,
                scale: 2,
                useCORS: true,
                processData: false,
                contentType: false,
            };
            html2canvas(data, html2canvasOptions).then(async (canvas) => {
                var imgData = await canvas.toDataURL("image/png");
                var imgWidth = 204;
                var imgHeight = 585;
                var position = 3;
                var pageHeight = 300
                var heightLeft = imgHeight - pageHeight

                var doc = new jsPDF("p", "mm");
                doc.addImage(
                    imgData,
                    "PNG",
                    3,
                    position,
                    imgWidth,
                    imgHeight,
                    undefined,
                    "FAST"
                );

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight + 10;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 3, position, imgWidth, imgHeight, undefined, 'FAST');
                    heightLeft -= pageHeight;
                }

                data.style.width = "auto";
                that.isPrinting = false;
                that.pdfLink = doc.output("datauristring");
                for (var i = 0; i < elms.length; i++) {
                    elms[i].style.display = "block";
                }
                if (sendSignature && sendSignature == true) {
                    that.isAskSignatureOpen = true
                    that.isPrinting = false;
                } else {
                    let id = uuidv4();
                    engine.creat_tmp_pdf(id, doc.output(), function (data) {
                        if (data.code === 0) {
                            engine.openPdf(id, function (urlPdf) {
                                Browser.open({ url: urlPdf });
                            });
                        }
                    });
                }
            });
        },
        formatDate(date, form) {
            let tmpDate = moment(date).format(form)
            if (tmpDate == 'Invalid date')
                tmpDate = undefined
            return tmpDate
        },
        deleteItemFromArrayByIndex(array, idx) {
            array.splice(idx, 1)
        },
        writeSignature() {
            if (this.isEditable === true) {
                this.isSignatureOpen = true;
            }
        },
        undo(key) {
            if (this.$refs[key].length == undefined)
                this.$refs[key].undoSignature();
            else
                this.$refs[key][0].undoSignature();
        },
        save(key) {
            const { isEmpty, data } = this.$refs[key].saveSignature();
            if (isEmpty === false) this.form[key] = data;
        },
        clear(key) {
            if (this.$refs[key].length == undefined)
                this.$refs[key].clearSignature();
            else
                this.$refs[key][0].clearSignature();
        },
        addSlotHoraire() {
            if (this.optionsObj.time_selection && Object.keys(this.slotHoraire).length >= 4) {
                this.slotHoraireListe.push(this.slotHoraire)
                this.slotHoraire = {}
                this.slotHoraire['technicien'] = this.user.user_name
                this.slotHoraire["date"] = this.form['DATE RAPPORT']
            }
            if (this.optionsObj.time_selection != true && Object.keys(this.slotHoraire).length >= 5) {
                this.slotHoraireListe.push(this.slotHoraire)
                this.slotHoraire = {}
                this.slotHoraire['technicien'] = this.user.user_name
                this.slotHoraire["date"] = this.form['DATE RAPPORT']
            }
        },
        getMatriculListeByName(name) {
            let that = this
            engine.get_matricule_liste_by_name(name, function (data) {
                if (data.code == 0) {
                    that.listeShortData['matricule'] = data.data.data.map((val) => val.fieldData)
                } else {
                    that.listeShortData['matricule'] = []
                }
            })
        },
        getClientListeByName(name) {
            let that = this
            engine.get_client_liste_by_name(name, function (data) {
                if (data.code == 0) {
                    that.listeShortData['client'] = data.data.data.map((val) => val.fieldData)
                } else {
                    that.listeShortData['client'] = []
                }
            })
        },
        getArticleListeByName(name) {
            let that = this
            engine.get_article_liste_by_name(name, function (data) {
                if (data.code == 0) {
                    that.listeShortData['article'] = data.data.data.map((val) => val.fieldData)
                    for (let i = 0; i < that.listeShortData['article'].length; i++) {
                        that.listeShortData['article'][i]['title'] = that.listeShortData['article'][i].PIECE_REFERENCE + ' - ' + that.listeShortData['article'][i].PIECE_DESIGNATION
                    }
                } else {
                    that.listeShortData['article'] = []
                }
            })
        },
        async preloadData() {
            let that = this

            await engine.get_client_by_id(this.itemsData.ID_client, function (clientData) {
                if (clientData.code == 0) {
                    that.form["client"] = clientData.data.data[0].fieldData

                    that.form["client"]['ADRESSE_LIV'] = that.itemsData['adliv']
                    that.form["client"]['COMP_ADRESSE_LIV'] = that.itemsData['compadliv']
                    that.form["client"]['VILLE_LIV'] = that.itemsData['villeliv']
                    that.form["client"]['CODE_POSTAL_LIV'] = parseInt(that.itemsData['dptliv'] + that.itemsData['cpliv'])
                }

                console.log("client preloadData", that.form["client"]);
            })

            if (this.itemsData.equipement && this.itemsData.equipement.length > 0) {
                this.form['equipement'] = JSON.parse(this.itemsData.equipement)
            }

            if (this.itemsData['DATE RAPPORT'] && this.itemsData['DATE RAPPORT'].length > 0)
                this.form['DATE RAPPORT'] = this.formatDate(this.itemsData['DATE RAPPORT'], 'YYYY-MM-DD')
            else
                this.form['DATE RAPPORT'] = moment().format('YYYY-MM-DD')


            this.loadPicturesfromDropBox()
            this.form["id"] = this.itemsData['id']
            this.form["link"] = this.itemsData['_LINK MOTEUR']
            this.form["Horametre"] = this.itemsData['Horametre']
            this.form["Kilometrage"] = this.itemsData['Kilometrage']
            this.form["themeDevis"] = this.itemsData['Theme devis']
            this.form["travauxExe"] = this.itemsData['Travaux exe']
            this.form["observation"] = this.itemsData['Observ']
            this.form["Message_Interne"] = this.itemsData['Message_Interne']
            this.form["type_travaux"] = this.itemsData['type_travaux']
            this.form['LIEU_INTERV'] = this.itemsData['LIEU_INTERV']
            this.form["time"] = this.itemsData['time_inter']
            this.form["num_contrat"] = this.itemsData['num_contrat']
            this.form["date_debut_location_TIMESTAMP"] = this.itemsData['date_debut_location_TIMESTAMP']
            this.form["date_fin_location_TIMESTAMP"] = this.itemsData['date_fin_location_TIMESTAMP']

            this.slotHoraire['technicien'] = this.user.user_name
            this.slotHoraire["date"] = this.form['DATE RAPPORT']

            this.form['photos'] = []

            engine.get_matricul_by_name(this.itemsData["N° MATR INT"], function (matData) {
                if (matData.code == 0) {
                    that.form["materiel"] = matData.data.data[0].fieldData
                }
                else {
                    that.form['materielMatricul'] = that.itemsData["N° MATR INT"]
                    that.form['materielUnknow'] = true
                }
            })



            if (this.itemsData['selection_specifique_client'] && this.itemsData['selection_specifique_client'].length != 0)
                this.selectionSpecifiqueClient = JSON.parse(this.itemsData['selection_specifique_client'])

            if (this.itemsData['Horaire_liste'] && this.itemsData['Horaire_liste'].length != 0)
                this.slotHoraireListe = JSON.parse(this.itemsData['Horaire_liste'])
            this.form["signaturePad"] = this.itemsData['Signature_web']

            if (this.itemsData['Check_liste'] && this.itemsData['Check_liste'].length != 0) {
                let liste = JSON.parse(this.itemsData['Check_liste'])
                if (liste.fieldata)
                    this.form["Check_liste"] = JSON.parse(this.itemsData['Check_liste']).fieldata
                else
                    this.form["Check_liste"] = JSON.parse(this.itemsData['Check_liste'])
            }
            console.log("liste", this.form["Check_liste"]);


            this.form['technicien'] = this.itemsData['Techniciens_Nom(1)']
            let orTable

            if (this.itemsData['Table'] == 'SAV_FICHE') {
                orTable = 'get_or_1_Liste_byLink'

                if (moment(this.itemsData['Debut']).format('hh:mm') != 'Invalid date' &&
                    moment(this.itemsData['Fin']).format('hh:mm') != 'Invalid date' &&
                    moment(this.itemsData['Debut']).format('YYYY-MM-DD') != 'Invalid date'
                ) {
                    this.form["timeStart"] = moment(this.itemsData['Debut']).format('hh:mm')
                    this.form["timeEnd"] = moment(this.itemsData['Fin']).format('hh:mm')
                    this.form["date"] = moment(this.itemsData['Debut']).format('YYYY-MM-DD')
                }
            } else if (this.itemsData['Table'] == 'SAV_2') {
                orTable = 'get_or_2_Liste_byLink'
                // if (this.slotHoraireListe.length == 0) {
                //     if (this.form['DATE RAPPORT']) {
                //         let dateFormated = this.form['DATE RAPPORT'].split('/')
                //         console.log("dateFormated", dateFormated);
                //         this.form["date"] = dateFormated[2] + '-' + dateFormated[0] + '-' + dateFormated[1]
                //     }
                //     this.form["timeStart"] = this.itemsData['Heure Arriv(1)']
                //     this.form["timeEnd"] = this.itemsData['Heure dep(1)']
                //     this.slotHoraireListe.push(
                //         { 'date': this.form["date"], 'timeStart': this.form["timeStart"], 'timeEnd': this.form["timeEnd"], 'technicien': this.itemsData["Techniciens_Nom(1)"] }
                //     )
                // } else {
                //     this.form["date"] = this.slotHoraireListe[0].date
                //     this.form["timeStart"] = this.slotHoraireListe[0].timeStart
                //     this.form["timeEnd"] = this.slotHoraireListe[0].timeEnd
                // }
            }

            engine[orTable](this.itemsData['_LINK MOTEUR'], function (data) {
                if (data.code == 0) {
                    let moteurListe = data.data.data.map((val) => val.fieldData)
                    for (let i = 0; i < moteurListe.length; i++) {
                        // let elem = that.stockListe.find((val) => val.PIECE_REFERENCE == moteurListe[i]['SAV REF PRODUIT'])
                        // console.log("elem", elem);
                        // console.log("prod", moteurListe[i]);
                        // if (elem){
                        that.items.push({ 'PIECE_DESIGNATION': moteurListe[i]["SAV DESIGNATION"], 'PIECE_REFERENCE': moteurListe[i]["SAV REF PRODUIT"], 'id': moteurListe[i]["id"], 'qte': moteurListe[i]["SAV QTE PROD"] })
                        // }else {

                        // }
                    }
                }
            })

        },
        verifScroll(elem) {
            if (elem) {
                return true
            } else {
                return false
            }
        },
        validForm() {
            let formIsValid = true
            let errorForm = ""

            let keyToCkeck = [
                { key: 'client', label: 'La séléction d\'un client est obligatoire VOIR : INFORMATION CLIENT' },
                { key: 'technicien', label: 'La séléction d\'un technicien est obligatoire VOIR : TECHNICIEN PRINCIPAL' },
            ]

            if (this.form['materielUnknow'] == false || this.form['materielUnknow'] == undefined) {
                keyToCkeck.push({ key: 'materiel', label: 'La séléction d\'un materiel est obligatoire VOIR : INFORMATION MACHINE' })
            }

            if (this.optionsObj["Mandatory_keys"] && JSON.parse(this.optionsObj["Mandatory_keys"]))
                keyToCkeck = keyToCkeck.concat(JSON.parse(this.optionsObj["Mandatory_keys"]));

            for (let i = 0; i < keyToCkeck.length; i++) {
                if (this.form[keyToCkeck[i].key] == undefined || this.form[keyToCkeck[i].key].length == 0) {
                    errorForm = keyToCkeck[i].label
                    formIsValid = false
                }
            }


            // if (this.slotHoraireListe && this.slotHoraireListe.length == 0) {
            //     errorForm = "L'ajout de minimum une horaire d'intervention est obligatoire VOIR : LISTE DATE / HORAIRE D'INTERVENTION"
            //     formIsValid = false
            // }

            if (formIsValid) {
                this.isUsed = true
                if (this.itemsData == undefined) {
                    this.creatOrSAV_FICHE()
                } else {
                    this.editOrSAV_FICHE()
                }
            } else {
                this.error.message = errorForm
                this.error.color = 'red'
                this.error.show = true
            }

        },
        creatOrSAV2(orNum, id) {
            let link = uuid.v1()
            let that = this
            let finalObj = {
                id,
                'N° RAP COMP': orNum,

                'retour': this.isRetour == true ? 'oui' : '',

                'Telephone': that.form['client']['TELEPHONE'],
                'ID_client': that.form['client']['id'],
                'societeliv': that.form['client']['SOCIETE'],
                'villeliv': that.form['client']['VILLE_LIV'],
                'adliv': that.form['client']['ADRESSE_LIV'],
                // 'cpliv': that.form['client']['CODE_POSTAL_LIV'],
                'compadliv': that.form['client']['COMP_ADRESSE'],

                '_LINK MOTEUR': link,
                'Message_Interne': that.form['Message_Interne'],
                'Check_liste': JSON.stringify({ 'fieldata': this.form['Check_liste'] }),
                'selection_specifique_client': JSON.stringify(that.selectionSpecifiqueClient),

                'DATE RAPPORT': that.formatDate(that.form['DATE RAPPORT'], 'MM/DD/YYYY'),

                'Horametre': that.form['Horametre'],
                'Kilometrage': that.form['Kilometrage'],
                'Theme devis': that.form['themeDevis'],
                'Observ': that.form['observation'],
                'Travaux exe': that.form['travauxExe'],
                'LIEU_INTERV': that.form['LIEU_INTERV'],

                'num_contrat': that.form['num_contrat'],
                'date_debut_location_TIMESTAMP': that.form['date_debut_location_TIMESTAMP'],
                'date_fin_location_TIMESTAMP': that.form['date_fin_location_TIMESTAMP'],

                // 'Horaire_liste': JSON.stringify(that.slotHoraireListe),
                // 'DATE RAPPORT': moment(that.slotHoraireListe[0]['date']).format('MM/DD/YYYY'),
                // 'Heure Arriv': that.slotHoraireListe[0]['timeStart'],
                // 'Heure dep': that.slotHoraireListe[0]['timeEnd'],
                'Signature_web': that.form['signaturePad'],
                'type_travaux': that.form['type_travaux'],
                'equipement': JSON.stringify(that.form['equipement']),

                "ID_Agence": that.user.user_id_agence,
                "ID_Societe": that.user.user_id_societe,

                // "type_travaux": 'réparation',
                "Table": 'SAV_2',
                "Status devis": 'Signé',
                "Statut": '140',
            }

            this.form["client"]['CODE_POSTAL_LIV'] = JSON.stringify(this.form["client"]['CODE_POSTAL_LIV'])
            if (this.form["client"]['CODE_POSTAL_LIV'] && this.form["client"]['CODE_POSTAL_LIV'].length >= 3) {
                finalObj['dptliv'] = this.form["client"]['CODE_POSTAL_LIV'].slice(0, 2)
                finalObj['cpliv'] = this.form["client"]['CODE_POSTAL_LIV'].substring(2)
            }

            if (that.form['materiel'] && that.form['materielUnknow'] != true) {
                finalObj['N° MATR INT'] = that.form['materiel']['MATRICULE']
                finalObj['MARQUE MACHINE'] = that.form['materiel']['MARQUE']
                finalObj['TYPE MACHINE'] = that.form['materiel']['TYPE']
                finalObj['sous_famille'] = that.form['materiel']['SOUS_FAMILLE']
                // finalObj['N° serie constr.'] = that.form['materiel']['NUMERO_SERIE']
                finalObj['N° serie constr'] = that.form['materiel']['NUMERO_SERIE']
            } else {
                finalObj['N° MATR INT'] = that.form['materielMatricul']
            }

            if (that.slotHoraireListe && that.slotHoraireListe[0]) {
                finalObj['Horaire_liste'] = JSON.stringify(that.slotHoraireListe)
                finalObj['DATE RAPPORT'] = moment(that.slotHoraireListe[0]['date']).format('MM/DD/YYYY')
                if (that.slotHoraireListe[0]['timeStart'])
                    finalObj['Heure Arriv'] = that.slotHoraireListe[0]['timeStart']
                if (that.slotHoraireListe[0]['timeEnd'])
                    finalObj['Heure dep'] = that.slotHoraireListe[0]['timeEnd']
                finalObj['time_inter'] = that.slotHoraireListe[0]['time']
            }

            if (that.form.technicien.NOM_TECHNICIEN)
                finalObj['Techniciens_Nom(1)'] = that.form.technicien.NOM_TECHNICIEN
            else
                finalObj['Techniciens_Nom(1)'] = that.form.technicien

            engine.creat_new_or_2(finalObj, function (data) {
                if (data.code == 0) {
                    if (that.itemsData && that.itemsData.CARA_MATERIEL) {
                        let jsonInfo = JSON.parse(that.itemsData.CARA_MATERIEL)

                        if (that.optionsObj.auto_creat_etatdl && that.optionsObj.auto_creat_etatdl == 1 && jsonInfo && jsonInfo.NUM_CONTRAT) {
                            engine.show_etadl(jsonInfo.NUM_CONTRAT, that.form['equipement'], that.form.materiel.MATRICULE, that.itemsData.CARA_MATERIEL, function () {
                            })
                        }
                    }
                    that.getOrListeAll()
                    if (that.items.length == 0)
                        that.$emit('close')
                    for (let i = 0; i < that.items.length; i++) {
                        let moteurInfo = that.items[i]
                        let elem = {
                            // id: moteurInfo.id,
                            "_LINK SAV": link,
                            "id_SAV": id,
                            "SAV REF PRODUIT": moteurInfo.PIECE_REFERENCE,
                            "SAV DESIGNATION": moteurInfo.PIECE_DESIGNATION,
                            "SAV QTE PROD": moteurInfo.qte,
                            "SAV PV UNIT": undefined,
                            "PIECE_TOTAL": undefined,
                            "FLAG_DELETE": undefined,
                            // ID_Societe: clientAgency.ID_Societe,
                            Index_Ligne: i + 1,
                        }
                        engine.creat_new_moteur_2_line(elem, function () {
                            if (i + 1 == that.items.length) {
                                that.$emit('close')
                            }
                        })
                    }
                }
            })
        },
        creatOrSAV_FICHE() {
            let that = this
            let id = uuid.v1()
            let link
            let signed = false


            let finalObj = this.getOrDataBasic
            finalObj['id'] = id
            // let finalObj = {
            //     id,
            // 'Horaire_liste': JSON.stringify(this.slotHoraireListe),
            // 'Signature_web': this.form['signaturePad'],
            // 'Kilometrage': this.form['Kilometrage'],

            // 'Telephone': this.form['client']['TELEPHONE'],
            // 'ID_client': this.form['client']['id'],
            // 'societeliv': this.form['client']['SOCIETE'],
            // 'villeliv': this.form['client']['VILLE_LIV'],
            // 'adliv': this.form['client']['ADRESSE_LIV'],
            // 'compadliv': this.form['client']['COMP_ADRESSE'],

            // 'DATE RAPPORT': this.formatDate(this.form['DATE RAPPORT'], 'MM/DD/YYYY'),

            // 'Horametre': this.form['Horametre'],
            // 'Check_liste': JSON.stringify({ 'fieldata': this.form['Check_liste'] }),
            // 'selection_specifique_client': JSON.stringify(this.selectionSpecifiqueClient),

            // 'LIEU_INTERV': this.form['LIEU_INTERV'],

            // 'Theme devis': this.form['themeDevis'],
            // 'Observ': this.form['observation'],
            // 'Travaux exe': this.form['travauxExe'],
            // 'Message_Interne': this.form['Message_Interne'],

            // "ID_Agence": this.user.user_id_agence,
            // "ID_Societe": this.user.user_id_societe,

            // 'type_travaux': this.form['type_travaux'],
            // "Table": 'SAV_FICHE',

            // "STATUS_DEVIS": 'En cours',
            // 'Statut': 130
            // }

            this.form["client"]['CODE_POSTAL_LIV'] = JSON.stringify(this.form["client"]['CODE_POSTAL_LIV'])
            if (this.form["client"]['CODE_POSTAL_LIV'] && this.form["client"]['CODE_POSTAL_LIV'].length >= 3) {
                finalObj['dptliv'] = this.form["client"]['CODE_POSTAL_LIV'].slice(0, 2)
                finalObj['cpliv'] = this.form["client"]['CODE_POSTAL_LIV'].substring(2)
            }

            if (that.form['materiel'] && that.form['materielUnknow'] != true) {

                finalObj['N° MATR INT'] = that.form['materiel']['MATRICULE']
                finalObj['MARQUE MACHINE'] = that.form['materiel']['MARQUE']
                finalObj['TYPE MACHINE'] = that.form['materiel']['TYPE']
                finalObj['sous_famille'] = that.form['materiel']['SOUS_FAMILLE']
                // finalObj['N° serie constr.'] = that.form['materiel']['NUMERO_SERIE']
                finalObj['N° serie constr'] = that.form['materiel']['NUMERO_SERIE']
            } else {
                finalObj['N° MATR INT'] = that.form['materielMatricul']
            }
            if (this.slotHoraireListe && this.slotHoraireListe[0]) {
                let date = this.slotHoraireListe[0].date

                if (this.slotHoraireListe[0] && this.slotHoraireListe[0].date.includes('-')) {
                    let tmpTab = this.slotHoraireListe[0].date.split('-')
                    date = tmpTab[1] + '/' + tmpTab[2] + '/' + tmpTab[0]
                }
                if (this.slotHoraireListe[0]['timeEnd'])
                    finalObj['Fin'] = date + ' ' + this.slotHoraireListe[0]['timeEnd']
                if (this.slotHoraireListe[0]['timeStart'])
                    finalObj['Debut'] = date + ' ' + this.slotHoraireListe[0]['timeStart']
                finalObj['time_inter'] = this.slotHoraireListe[0]['time']
            }


            link = uuid.v1()

            finalObj['_LINK MOTEUR'] = link

            if (this.form.signaturePad && this.form.signaturePad.length > 0) {
                signed = true
                finalObj['Statut'] = '140'
            }

            if (this.form.technicien.NOM_TECHNICIEN)
                finalObj['Techniciens_Nom(1)'] = this.form.technicien.NOM_TECHNICIEN
            else
                finalObj['Techniciens_Nom(1)'] = this.form.technicien

            engine.creat_new_or_1(finalObj, function (data) {
                if (data.code == 0) {
                    that.updateDropboxPhotos(data.data.orNum)
                    that.getOrListeAll()
                    if (that.items.length == 0) {
                        if (signed == false) {
                            that.closeModal()
                        } else {
                            that.creatOrSAV2(data.data.orNum, id)
                        }
                    }
                    for (let i = 0; i < that.items.length; i++) {
                        let moteurInfo = that.items[i]
                        let elem = {
                            // id: uuid.v1(),
                            "_LINK SAV": link,
                            "id_SAV": id,
                            "SAV REF PRODUIT": moteurInfo.PIECE_REFERENCE,
                            "SAV DESIGNATION": moteurInfo.PIECE_DESIGNATION,
                            "SAV QTE PROD": moteurInfo.qte,
                            "SAV PV UNIT": undefined,
                            "PIECE_TOTAL": undefined,
                            "FLAG_DELETE": undefined,
                            // ID_Societe: clientAgency.ID_Societe,
                            Index_Ligne: i + 1,
                        }
                        engine.creat_new_moteur_1_line(elem, function () {
                            if (i + 1 == that.items.length) {
                                if (signed == false) {
                                    that.closeModal()
                                } else {
                                    that.creatOrSAV2(data.data.orNum, id)
                                }
                            }
                        })
                    }
                }
            })
        },
        editOrSAV_FICHE() {
            let that = this
            // let id = uuid.v1()
            let link

            let finalObj = this.getOrDataBasic

            this.form["client"]['CODE_POSTAL_LIV'] = JSON.stringify(this.form["client"]['CODE_POSTAL_LIV'])
            if (this.form["client"]['CODE_POSTAL_LIV'] && this.form["client"]['CODE_POSTAL_LIV'].length >= 3) {
                finalObj['dptliv'] = this.form["client"]['CODE_POSTAL_LIV'].slice(0, 2)
                finalObj['cpliv'] = this.form["client"]['CODE_POSTAL_LIV'].substring(2)
            }

            if (this.form["postalCode"] && this.form["postalCode"].length > 3) {
                console.log(1);
                finalObj['dptliv'] = this.form["postalCode"].slice(0, 2)
                finalObj['cpliv'] = this.form["postalCode"].substring(2);
            }

            if (that.form['materiel'] && that.form['materielUnknow'] != true) {
                finalObj['N° MATR INT'] = that.form['materiel']['MATRICULE']
                finalObj['MARQUE MACHINE'] = that.form['materiel']['MARQUE']
                finalObj['TYPE MACHINE'] = that.form['materiel']['TYPE']
                finalObj['sous_famille'] = that.form['materiel']['SOUS_FAMILLE']
                // finalObj['N° serie constr.'] = that.form['materiel']['NUMERO_SERIE']
                finalObj['N° serie constr'] = that.form['materiel']['NUMERO_SERIE']
            } else {
                finalObj['N° MATR INT'] = that.form['materielMatricul']
            }

            if (this.slotHoraireListe && this.slotHoraireListe[0]) {
                let date = this.slotHoraireListe[0].date
                if (this.slotHoraireListe[0].date.includes('-')) {
                    let tmpTab = this.slotHoraireListe[0].date.split('-')
                    date = tmpTab[1] + '/' + tmpTab[2] + '/' + tmpTab[0]
                }

                if (this.slotHoraireListe[0]['timeEnd'])
                    finalObj["Fin"] = date + ' ' + this.slotHoraireListe[0]['timeEnd']
                if (this.slotHoraireListe[0]['timeStart'])
                    finalObj["Debut"] = date + ' ' + this.slotHoraireListe[0]['timeStart']
                finalObj['time_inter'] = this.slotHoraireListe[0]['time']
            }

            if (this.itemsData) {
                link = this.itemsData['_LINK MOTEUR']
                finalObj['N° RAP COMP'] = this.itemsData['N° RAP COMP']
            } else {
                link = uuid.v1()
            }

            finalObj['_LINK MOTEUR'] = link

            if (this.form.signaturePad && this.form.signaturePad.length > 0) {
                finalObj['Statut'] = '140'
                this.creatOrSAV2(this.itemsData['N° RAP COMP'], this.itemsData['id'])
            }
            if (this.form.technicien.NOM_TECHNICIEN)
                finalObj['Techniciens_Nom(1)'] = this.form.technicien.NOM_TECHNICIEN
            else
                finalObj['Techniciens_Nom(1)'] = this.form.technicien

            engine.edit_or(finalObj, function (data) {
                if (data.code == 0) {
                    that.updateDropboxPhotos(data.data.orNum)
                    engine.delete_old_moteur_by_link(link, function () {
                        that.getOrListeAll()
                        if (that.items.length == 0)
                            that.$emit('close')
                        for (let i = 0; i < that.items.length; i++) {
                            let moteurInfo = that.items[i]
                            let elem = {
                                // id: moteurInfo.id,
                                "_LINK SAV": link,
                                "id_SAV": that.itemsData.id,
                                "SAV REF PRODUIT": moteurInfo.PIECE_REFERENCE,
                                "SAV DESIGNATION": moteurInfo.PIECE_DESIGNATION,
                                "SAV QTE PROD": moteurInfo.qte,
                                "SAV PV UNIT": undefined,
                                "PIECE_TOTAL": undefined,
                                "FLAG_DELETE": undefined,
                                // ID_Societe: clientAgency.ID_Societe,
                                Index_Ligne: i + 1,
                            }
                            engine.creat_new_moteur_1_line(elem, function () {
                                if (i + 1 == that.items.length) {
                                    that.$emit('close')
                                }
                            })
                        }
                    })
                }
            })
        },
        addAcc() {
            if (this.form['isSelectedAccessoirePerso'] == true) {
                // { text: "Référence", value: "PIECE_REFERENCE", sortable: true },
                // { text: "Désignation", value: "PIECE_DESIGNATION", sortable: true },
                // { text: "Quantitée", value: "qte", sortable: true, width: '200' },
                // { text: "", value: "delete", sortable: false, width: '0' },

                this.items.push({ 'PIECE_REFERENCE': '', 'PIECE_DESIGNATION': this.selectedAccessoire, 'qte': 1 })
                this.selectedAccessoire = undefined
            } else {
                this.selectedAccessoire['qte'] = 1
                this.items.push(this.selectedAccessoire)
                this.selectedAccessoire = undefined
            }
        },
        clickRow(data) {
            console.log("clickRow", data);
        }
    },
    computed: {
        getOrDataBasic() {
            let data = {
                'Horaire_liste': JSON.stringify(this.slotHoraireListe),
                'Signature_web': this.form['signaturePad'],
                'Kilometrage': this.form['Kilometrage'],

                'Telephone': this.form['client']['TELEPHONE'],
                'ID_client': this.form['client']['id'],
                'societeliv': this.form['client']['SOCIETE'],
                'villeliv': this.form['client']['VILLE_LIV'],
                'adliv': this.form['client']['ADRESSE_LIV'],
                'compadliv': this.form['client']['COMP_ADRESSE'],

                'LIEU_INTERV': this.form['LIEU_INTERV'],
                'DATE RAPPORT': this.formatDate(this.form['DATE RAPPORT'], 'MM/DD/YYYY'),
                "Table": 'SAV_FICHE',

                'Horametre': this.form['Horametre'],
                'Check_liste': JSON.stringify({ 'fieldata': this.form['Check_liste'] }),
                'selection_specifique_client': JSON.stringify(this.selectionSpecifiqueClient),
                'equipement': JSON.stringify(this.form['equipement']),

                "ID_Agence": this.user.user_id_agence,
                "ID_Societe": this.user.user_id_societe,

                'Theme devis': this.form['themeDevis'],
                'Observ': this.form['observation'],
                'Travaux exe': this.form['travauxExe'],
                'Message_Interne': this.form['Message_Interne'],
                'type_travaux': this.form['type_travaux'],

                "STATUS_DEVIS": 'En cours',
                'Statut': 130
            }

            return data
        },
        headers2() {
            let header = [
                { text: "Equipement", value: "CH_DESIGNATION", sortable: false },
                { text: "Valeur", value: "CH_ETAT", sortable: false },
            ]

            if (this.form['Check_liste'][0] && this.form['Check_liste'][0].CH_OLD_ETAT != undefined)
                header.push({ text: "Ancienne valeur", value: "CH_OLD_ETAT", sortable: false })

            return header
        },
        // getClientListe() {
        //     let client
        //     let that = this

        //     if (that.filtreNomtech && that.filtreNomtech.length > 0) {
        //         client = that.clientListe.filter((val) => val.SOCIETE.toLocaleLowerCase().includes(that.filtreNomtech.toLocaleLowerCase()))
        //         console.log("eee", client);
        //     } else {
        //         client = that.clientListe
        //     }

        //     console.log("getClientListe", client);
        //     return client
        // },
        getMandatoryItems() {
            let keyToCkeck = [
                { key: 'client', label: 'La séléction d\'un client est obligatoire VOIR : INFORMATION CLIENT' },
                { key: 'materiel', label: 'La séléction d\'un materiel est obligatoire VOIR : INFORMATION MACHINE' },
                { key: 'technicien', label: 'La séléction d\'un technicien est obligatoire VOIR : TECHNICIEN PRINCIPAL' },
            ]

            if (this.optionsObj["Mandatory_keys"] && JSON.parse(this.optionsObj["Mandatory_keys"]))
                keyToCkeck = keyToCkeck.concat(JSON.parse(this.optionsObj["Mandatory_keys"]));
            return keyToCkeck
        },
        materielElemToShow() {
            return [{
                key: "FAMILLE",
                label: "Famille"
            },
            {
                key: "SOUS_FAMILLE",
                label: "Sous famille"
            }, {
                key: "NUMERO_SERIE",
                label: "Numéro de série"
            }, {
                key: "MARQUE",
                label: "Marque"
            }, {
                key: "TYPE",
                label: "Type"
            }
            ]
        }
    }
}
</script>

<style>
.edition {
    background-color: white;
    color: black;
    border: none !important;
}

.disabledFormData {
    background-color: white !important;
}

.stepContainer {
    background-color: #E0E0E0;
    padding: 15px;
    border-radius: 10px;
    border: solid 1px #BDBDBD;
}

.sav-form table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.sav-form td,
.sav-form th {
    border: 1px solid #ECEFF1;
    text-align: left;
    padding: 8px;
}

.sav-form tr:nth-child(even) {
    background-color: #ECEFF1;
}
</style>