<template>
  <v-app>
    <v-main :style="{ 'background-color': getBackgroundColor }">
      <v-app-bar app fixed style="left:0px;" density="compact" v-if="(getApp != 'LOGIN' && getApp.includes('/') == false) &&
      (getApp != 'SIGNATURE DOUBLE AUTHENTIFICATION')">
        <v-app-bar-title class="py-1" style="border: 1px solid lightgrey; border-radius: 5px" align="center">
          <div v-if="getApp != ''">
            {{ getApp }}
          </div>
          <div v-else>
            {{ getAppBarTitile }}
          </div>
          <!-- <div style="position: absolute; top: 0px; right: 0px; height: 100%;" class="pa-3">
            {{ speed }}
          </div> -->
        </v-app-bar-title>
        <template v-if="!getApp == ''" v-slot:prepend>
          <v-app-bar-nav-icon @click="goTo('/')" icon="mdi-arrow-left-circle" color="red" style="font-size: 20px">
          </v-app-bar-nav-icon>
        </template>
        <template v-else v-slot:append>
          <v-btn icon="mdi-logout" color="red" style="font-size:20px;" @click="logOut()"></v-btn>
        </template>
      </v-app-bar>
      <v-col class="pa-0">
        <v-card :variant="'text'" :style="showHeader ? 'height: 94.5vh; overflow-y: auto' : ''">
          <v-col class="pa-1 pt-2 pb-0">
            <router-view v-if="!isLoading" />
            <div v-else>
              <v-col align="center" style="margin-top: 35vh" class="py-0">
                <v-progress-circular size="110" indeterminate color="primary"></v-progress-circular>
              </v-col>
            </div>
          </v-col>
        </v-card>
      </v-col>
    </v-main>
  </v-app>
</template>

<script>
import engine from "@/core/index";

export default {
  // name: "",
  data() {
    return {
      speed: null,
      selectedPage: undefined,
      isLoading: true,
      webApplicationToShow: [],
      items: [
        {
          text: "Planning Disponibilité",
          href: "planning-disponibilite",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Planning Livraison",
          href: "planning-livraison",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Planning Technicien",
          href: "planning-technicien",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Planning gestion RDV vélo",
          href: "planning-gestion-rdv-velo",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Statistiques",
          href: "statistiques",
          color: "#FFF176",
          icon: "mdi-chart-bar",
          show: false,
        },
        {
          text: "Email visualisation",
          href: "email-visualisation",
          color: "#5C6BC0",
          icon: "mdi-email",
          show: false,
        },
        {
          text: "Etat des lieux materiel",
          href: "etat-des-lieux-materiel",
          color: "#8D6E63",
          icon: "mdi-clipboard-file",
          show: false,
        },
        {
          text: "Etat des lieux vélo",
          href: "etat-des-lieux-velo",
          color: "#8D6E63",
          icon: "mdi-clipboard-file",
          show: false,
        },
        {
          text: "Gestion livraison",
          href: "gestion-livraison",
          color: "#BDBDBD",
          icon: "mdi-map",
          show: false,
        },
        {
          text: "Signature en ligne",
          href: "signature-en-ligne",
          color: "#BDBDBD",
          icon: "mdi-draw",
          show: false,
        },
        {
          text: "SAV Gestion",
          href: "sav-gestion",
          color: "#BDBDBD",
          icon: "mdi-tools",
          show: false,
        },
        {
          text: "Contrat Gestion",
          href: "contrat-gestion",
          color: "#BDBDBD",
          icon: "mdi-file-document-outline",
          show: false,
        },
      ],
    };
  },
  mounted() {
    this.updateNetworkSpeed();
    this.updateUser();
    this.selectedPage = window.location.pathname.replace("/", "");
  },
  methods: {
    // fetch('https://' +  process.env.VUE_APP_MAIN_URL) // URL to a file you want to download

    measureNetworkSpeed() {
      let that = this
      const startTime = performance.now();

      fetch('https://' + process.env.VUE_APP_MAIN_URL) // URL of the website you want to measure
        .then(response => {
          const endTime = performance.now();
          const duration = (endTime - startTime) / 100000; // in seconds
          that.speed = this.calculateSpeed(response.headers, duration)
        })
        .catch(error => {
          console.error('Error measuring network speed:', error);
          that.speed = null;
        });
    },

    calculateSpeed(headers, duration) {
      const contentLength = parseFloat(headers.get('Content-Length'));
      if (!contentLength || isNaN(contentLength)) {
        console.error('Content-Length header not found or invalid.');
        return null;
      }

      const fileSizeBytes = contentLength;
      const fileSizeMb = fileSizeBytes / 1024 / 1024;
      const speedMbps = (fileSizeMb / duration).toFixed(6); // calculate speed in Mbps
      return speedMbps;
    },

    updateNetworkSpeed() {
      console.log("updateNetworkSpeed");
      this.measureNetworkSpeed();
      setInterval(() => {
        this.measureNetworkSpeed();
      }, 5000); // Update every 5 seconds
    },

    updateUser() {
      let that = this;
      let token = localStorage.getItem("userToken");
      let nbrDivision = this.$route.path.split('/').filter((val) => val.length > 0).length
      if (nbrDivision <= 1) {
        engine.get_user_by_token(token, function (data) {
          if (data.code == 0) {
            that.webApplicationToShow =
              data.data.data.data[0].fieldData.web_application_to_show.split(",");
            for (let i = 0; i < that.webApplicationToShow.length; i++) {
              that.items.find(
                (val) => val.href == that.webApplicationToShow[i]
              ).show = true;
            }
            that.$store.state.webApplicationToShow = that.webApplicationToShow;
            let user = data.data.data.data[0].fieldData;
            delete user.user_id;
            delete user.user_password;
            that.$store.state.user = user;
          }
          // else {
          //   that.$router.push("/login");
          // }
          that.isLoading = false;
        });
      } else {
        that.isLoading = false;
      }
    },
    logOut() {
      localStorage.clear();
      this.goTo("/login");
    },
    goTo(href) {
      this.$router.push(href);
    },
  },
  computed: {
    getBackgroundColor() {
      let url = this.$route.fullPath.replace("/", "");
      let color = "#90A4AE";
      let colorTab = {
        "": "#E0E0E0",
        "login": "#E0E0E0",
        "planning-disponibilite": "#90A4AE",
        "planning-livraison": "#90A4AE",
        "planning-technicien": "#90A4AE",
        "planning-gestion-rdv-velo": "#E0E0E0",
        "email-visualisation": "#90A4AE",
        "etat-des-lieux-materiel": "white",
        "statistiques": "#90A4AE",
        "gestion-livraison": "#90A4AE",
        "signature-en-ligne": "#90A4AE",
        "sav-gestion": "#90A4AE",
      };
      if (colorTab[url] != undefined) color = colorTab[url];
      return color;
    },
    getAppBarTitile() {
      return "ACCEN - Web applications";
    },
    getApp() {
      return this.$route.fullPath
        .replace("/", "")
        .replaceAll("-", " ")
        .split("?")[0]
        .toUpperCase();
    },
    showHeader() {
      let show = ["/", "/login"];
      return !show.includes(this.$route.fullPath);
    },
    isHomePage() {
      let show = ["/"];
      return show.includes(this.$route.fullPath);
    },
  },
};
</script>

<style>
@import "./style.css";
</style>
